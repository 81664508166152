<template>
  <div>
    <c-search-box>
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="none" name="plantCd" v-model="searchParam.plantCd" @input="getList" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            type="year"
            :range="true"
            defaultStart="-1y"
            defaultEnd="0y"
            label="비교년도"
            name="selfInspectionYear"
            v-model="selfInspectionYear"
            @input="getList"
          />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="table"
          title="온실가스별 배출량"
          tableId="table"
          :columnSetting="false"
          :usePaging="false"
          :filtering="false"
          :hideBottom="true"
          :columns="grid.columns"
          :data="grid.data"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="background:#fff;">
        <apexchart 
          ref="chart1" 
          type="radar"
          height="600" 
          :options="chart.chartOptions" 
          :series="chart.series"></apexchart>
      </div>
      </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: "compliance",
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      revisionItems: [],
      grid: {
        columns: [
          {
            name: 'itemMstName',
            field: 'itemMstName',
            label: '온실가스',
            align: 'left',
            sortable: false
          },
          {
            name: 'maxChangeScoring',
            field: 'maxChangeScoring',
            label: 'GWP(지구온난화지수)',
            align: 'center',
            style: 'width:150px',
            sortable: false
          },
          {
            name: 'col2021',
            field: 'col2021',
            label: '2021년',
            align: 'center',
            style: 'width:120px',
            sortable: false
          },
          {
            name: 'col2022',
            field: 'col2022',
            label: '2022년',
            align: 'center',
            style: 'width:120px',
            sortable: false
          },
        ],
        data: [],
      },
      columnsset: [

      ],
      searchParam: {
        plantCd: null,
        startYear: null,
        endYear: null,
        sysRevision: 1,
        selfInspectionTypeCd: 'SITC000001', // 정기자체감사
      },
      selfInspectionYear: [],
      editable: true,
      listUrl: '',
      revisionUrl: '',
      chart: {
        chartOptions: {
         // 그래프 설정
          chart: {
            height: 350,
            type: 'radar',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: true
            },
            zoom: {
              enabled: false
            },
          },
          plotOptions: {
            radar: {
              size: 250,
              polygons: {
                strokeColors: '#e9e9e9',
                fill: {
                  colors: ['#f8f8f8', '#fff']
                }
              }
            }
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
          },
          title: {
            text: '년도별 온실가스 배출량 비교'
          },
          stroke: {
            width: 2
          },
          fill: {
            opacity: 0.1
          },
          markers: {
            size: 5,
            hover: {
              size: 10
            },
            discrete: []
          },
          xaxis: {
            categories:[]
          },
          yaxis: {
            min: 0,
            tickAmount: 5,
          },
          tooltip: {
            enabled: false,
          }
        },
        series: [],
      }
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.pca.result.graph.list.url;
      this.revisionUrl = selectConfig.sop.pca.item.revs.item;
      // this.getRevision();
      this.grid.data = [
        {
          itemMstName: 'CO2',
          maxChangeScoring: '1',
          col2021: '123',
          col2022: '100',
        },
        {
          itemMstName: 'CH4',
          maxChangeScoring: '21',
          col2021: '98',
          col2022: '104',
        },
        {
          itemMstName: 'N2O',
          maxChangeScoring: '131',
          col2021: '120',
          col2022: '110',
        },
        {
          itemMstName: 'HFCs',
          maxChangeScoring: '111',
          col2021: '80',
          col2022: '70',
        },
        {
          itemMstName: 'PFCs',
          maxChangeScoring: '181',
          col2021: '110',
          col2022: '210',
        },
        {
          itemMstName: 'SF6',
          maxChangeScoring: '231',
          col2021: '180',
          col2022: '150',
        },
      ]
      this.getList();
    },
    getRevision() {
      // this.$http.url = this.revisionUrl;
      // this.$http.type = 'GET';
      // this.$http.request((_result) => {
      //   this.revisionItems = _result.data;
      //   this.searchParam.sysRevision = this.revisionItems[0].code;
      //   this.getList();
      // },);
    },
    getList() {
      if (this.selfInspectionYear.length === 2) {
        this.searchParam.startYear = this.selfInspectionYear[0];
        this.searchParam.endYear = this.selfInspectionYear[1];
      } else {
        this.searchParam.startYear = this.$comm.getPrevYear()-1;
        this.searchParam.endYear = this.$comm.getThisYear();
      }
      this.chart.chartOptions.xaxis.categories = ['CO2','CH4','N2O','HFCs','PFCs','SF6'];
      this.chart.series = [{
        name: '2021년 배출량',
        data: [123,98,120,80,110,180]
      },{
        name: '2022년 배출량',
        data: [100,104,110,70,210,150]
      }]

      this.$refs['chart1'].refresh();
    },
  },
};
</script>
